import { API } from '@cranium/api'
import { QueryParams } from '@cranium/queryparams'

export const QS = new QueryParams()

function hasFile(obj) {
  return obj instanceof FormData
}
function prepareBody(body, isMultipartFormData) {
  if(isMultipartFormData) {
    return body
  }
  return JSON.stringify(body)
}

const api = new API({
  baseURL: `${process.env.API_URL}`,
  queryFuntion: QS.buildQueryParams,
  isMultipartFormData: hasFile,
  prepareBody,
})

export default api

import { reset } from '@cranium/cache'
import { gettext } from '@cranium/i18n'
import { Link, withRouter } from '@cranium/router'
import CloseIcon from '@mui/icons-material/Close'
import LogoutIcon from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
import SettingsIcon from '@mui/icons-material/SettingsOutlined'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/system/Box'
import PropTypes from 'prop-types'
import { useCallback, useState, useEffect } from 'react'
import { connect } from 'react-redux'

import logo from '@img/logo.svg'
import useMedia from 'common/hooks/useMedia'
import { useUser } from 'common/providers/user'

Header.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  isNavOpen: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  handleNavigationToggle: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
}

function Header({ isLoggedIn, isNavOpen, history, handleNavigationToggle, reset }) {
  const { isDesktop } = useMedia()
  const { user } = useUser()
  const [anchorElUser, setAnchorElUser] = useState(null)

  const Icon = !isDesktop && isNavOpen ? CloseIcon : MenuIcon

  useEffect(() => {
    !isDesktop && handleNavigationToggle(false)
  }, [handleNavigationToggle, history.location.pathname, isDesktop])

  const handleOpenUserMenu = useCallback((event) => {
    setAnchorElUser(event.currentTarget)
  }, [])
  const handleCloseUserMenu = useCallback(() => {
    setAnchorElUser(null)
  }, [])

  if(!isLoggedIn) {
    return null
  }

  return (
    <Box
      as="header"
      sx={{
        paddingX: '12px',
        paddingY: 1,
        height: { xs: 50, md: 72 },
        border: 1,
        borderColor: 'grey.300',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <IconButton onClick={() => handleNavigationToggle(!isNavOpen)} aria-label="nav" sx={{ mr: { xs: 0, sm: 2 }, ml: -1, color: 'grey.700' }}>
        <Icon sx={{ width: { xs: 24, sm: 32 }, height: { xs: 24, sm: 32 } }} />
      </IconButton>
      <Link to="root"><Box as="img" sx={{ width: { xs: 75, md: 136 }, display: 'block' }} src={logo} alt="Getpart" /></Link>
      <Box sx={{ flexGrow: 0, ml: 'auto' }}>
        <Button
          variant="text"
          onClick={handleOpenUserMenu}
          sx={{
            p: 0,
            fontSize: { xs: 0, sm: 14 },
            fontWeight: 700,
            color: 'grey.700',
            display: 'flex',
            alignItems: 'center',
            minWidth: 'auto',
            pl: { xs: 0, sm: 2 },
          }}
        >
          {user?.company?.name}
          <Avatar
            src={user?.profile?.image?.thumbnail || user?.profile?.image?.original}
            sx={{ bgcolor: 'grey.500', height: 32, width: 32, fontSize: 14, ml: { xs: 1, sm: 3 } }}
          >
            {user?.firstName.slice(0, 1)}{user?.lastName.slice(0, 1)}
          </Avatar>
        </Button>
        <Menu
          elevation={2}
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{ mt: { xs: 4, sm: '52px' } }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={!!anchorElUser}
          onClose={handleCloseUserMenu}
        >
          <Box sx={{ color: 'grey.500', fontWeight: 600, fontSize: 12, padding: '8px 16px' }}>
            {user?.firstName} {user?.lastName}
          </Box>
          <MenuItem onClick={() => { handleCloseUserMenu(); history.push('user-info') }}>
            <SettingsIcon sx={{ color: 'grey.500', mr: 1 }} />
            {gettext('Account settings')}
          </MenuItem>
          <MenuItem onClick={() => { handleCloseUserMenu(); reset() }} sx={{ color: 'grey.500' }}>
            <LogoutIcon sx={{ color: 'grey.500', mr: 1 }} />
            {gettext('Log out')}
          </MenuItem>
          <Box
            sx={{
              borderTop: 0.5,
              borderTopColor: 'grey.300',
              mt: 1,
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              p: 2,
              pb: 1,
              a: {
                textDecoration: 'none',
                fontSize: 10,
                fontWeight: 700,
                color: 'grey.500',
              },
            }}
          >
            <a href="https://getpart.io/privacy" target="_blank" rel="noreferrer">{gettext('Privacy')}</a>
            <a href="https://getpart.io/terms-and-conditions" target="_blank" rel="noreferrer">{gettext('Terms')}</a>
            <a href="mailto:hello@getpart.io" target="_blank" rel="noreferrer">hello@getpart.io</a>
          </Box>
        </Menu>
      </Box>
    </Box>
  )
}

export default connect(null, { reset })(withRouter(Header))

import { Suspense, lazy } from 'react'

import Loader from 'common/widgets/Loader'

const CreatePriceRequest = lazy(() => import('./create-price-request'))
const PriceRequests = lazy(() => import('./price-requests'))
const RequestDetail = lazy(() => import('./price-requests/request-detail'))
const CompareSuppliers = lazy(() => import('./price-requests/compare-suppliers'))
const OrderSummary = lazy(() => import('./price-requests/order-summary'))
const Proposals = lazy(() => import('./proposals'))
const ProposalDetail = lazy(() => import('./proposals/proposal-detail'))
const MyOrders = lazy(() => import('./my-orders'))
const IncomingOrders = lazy(() => import('./incoming-orders'))
const OrderDetail = lazy(() => import('./order-detail'))
const Suppliers = lazy(() => import('./suppliers'))
const PurchaseParts = lazy(() => import('./purchase-parts'))

const routes = [
  {
    path: '/',
    routes: [
      {
        path: '/',
        redirectTo: 'price-requests',
        exact: true,
      },
      {
        path: '/price-requests',
        component: () => <Suspense fallback={<Loader isLoading />}><PriceRequests/></Suspense>,
        name: 'price-requests',
        exact: true,
      },
      {
        path: '/price-requests/create/:id?',
        component: () => <Suspense fallback={<Loader isLoading />}><CreatePriceRequest/></Suspense>,
        name: 'create-price-request',
        exact: true,
      },
      {
        path: '/price-requests/:id',
        component: () => <Suspense fallback={<Loader isLoading />}><RequestDetail/></Suspense>,
        name: 'price-request',
        exact: true,
      },
      {
        path: '/price-requests/:id/compare/:partId',
        component: () => <Suspense fallback={<Loader isLoading />}><CompareSuppliers/></Suspense>,
        name: 'compare-suppliers',
        exact: true,
      },
      {
        path: '/price-requests/order-summary/:id',
        component: () => <Suspense fallback={<Loader isLoading />}><OrderSummary/></Suspense>,
        name: 'order-summary',
      },
      {
        path: '/purchase-parts',
        component: () => <Suspense fallback={<Loader isLoading />}><PurchaseParts/></Suspense>,
        name: 'purchase-parts',
        exact: true,
      },
      {
        path: '/proposals',
        component: () => <Suspense fallback={<Loader isLoading />}><Proposals/></Suspense>,
        name: 'proposals',
        exact: true,
      },
      {
        path: '/proposals/:id',
        component: () => <Suspense fallback={<Loader isLoading />}><ProposalDetail/></Suspense>,
        name: 'proposal-detail',
      },
      {
        path: '/orders',
        component: () => <Suspense fallback={<Loader isLoading />}><MyOrders/></Suspense>,
        name: 'orders',
        exact: true,
      },
      {
        path: '/orders/:id',
        component: () => <Suspense fallback={<Loader isLoading />}><OrderDetail/></Suspense>,
        name: 'order-detail',
      },
      {
        path: '/incoming-orders',
        component: () => <Suspense fallback={<Loader isLoading />}><IncomingOrders/></Suspense>,
        name: 'incoming-orders',
        exact: true,
      },
      {
        path: '/incoming-orders/:id',
        component: () => <Suspense fallback={<Loader isLoading />}><OrderDetail/></Suspense>,
        name: 'incoming-order-detail',
      },
      {
        path: '/suppliers',
        component: () => <Suspense fallback={<Loader isLoading />}><Suppliers/></Suspense>,
        name: 'suppliers',
      },
    ],
  },
]

export default routes

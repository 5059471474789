import { hasPermission } from '@cranium/access'
import { useQuery } from '@cranium/resource'
import PropTypes from 'prop-types'
import { useContext, createContext, useEffect } from 'react'

import { access } from 'common/session'
import Loader from 'common/widgets/Loader'

import User from './user.gql'


const UserContext = createContext()
UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export function UserProvider({ children }) {
  const isLoggedIn = hasPermission(access.F_PROTECTED)
  const { data, request, isLoading } = useQuery(User, { namespace: 'user', parseValue: 'data.User' })

  useEffect(() => {
    if(isLoggedIn) {
      request()
    }
  }, [isLoggedIn, request])

  return (
    <UserContext.Provider value={{ user: data, getUser: request }}>
      <Loader isLoading={Boolean(!data && isLoading)}>
        {children}
      </Loader>
    </UserContext.Provider>
  )
}

export function useUser() {
  return useContext(UserContext)
}

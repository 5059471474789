import { Suspense, lazy } from 'react'

import { access } from 'common/session'
import Loader from 'common/widgets/Loader'

const LoginForm = lazy(() => import('./login'))
const RequestAccessForm = lazy(() => import('./request-access'))
const ResetForm = lazy(() => import('./reset'))
const SetPasswordForm = lazy(() => import('./set-password'))
const ChangePasswordForm = lazy(() => import('./change-password'))

const routes = [
  {
    path: '/',
    routes: [
      {
        path: '/',
        exact: true,
        redirectTo: 'login',
      },
      {
        path: '/login',
        component: () => <Suspense fallback={<Loader isLoading />}><LoginForm/></Suspense>,
        name: 'login',
        access: access.F_UNAUTHORISED,
        accessRedirectTo: 'dashboard',
      },
      {
        path: '/request-access',
        component: () => <Suspense fallback={<Loader isLoading />}><RequestAccessForm/></Suspense>,
        name: 'request-access',
        access: access.F_UNAUTHORISED,
        accessRedirectTo: 'dashboard',
      },
      {
        path: '/forgot-password',
        component: () => <Suspense fallback={<Loader isLoading />}><ResetForm/></Suspense>,
        name: 'forgot',
        access: access.F_UNAUTHORISED,
        accessRedirectTo: 'dashboard',
      },
      {
        path: '/set-password',
        component: () => <Suspense fallback={<Loader isLoading />}><SetPasswordForm/></Suspense>,
        name: 'set-password',
        access: access.F_UNAUTHORISED,
        accessRedirectTo: 'dashboard',
      },
      {
        path: '/change-password',
        component: () => <Suspense fallback={<Loader isLoading />}><ChangePasswordForm/></Suspense>,
        name: 'change-password',
        access: access.F_PROTECTED,
        accessRedirectTo: 'auth',
      },
    ],
  },
]

export default routes

export default function companyQueryParams(companyId) {
  return `
      query Company {
        Company(id: "${companyId}") {
            id
            name
            description
            phone
            registrationNumber
            yearOfRegistration
            cover {
                id
                original
                thumbnail
            }
            vatNumber
            email
            twitter
            instagram
            facebook
            linkedin
            members {
                email
                phone
                position
                fullName
                profile {
                    image {
                        thumbnail
                        original
                    }
                }
            }
            website
            nace {
                id
                label
            }
            addresses {
                id
                address
                additionalAddress
                isDefault
                zipcode
                country {
                    name
                    phonecode
                    isoCode
                    flag
                    currency
                    latitude
                    longitude
                    suppliersCount
                }
                city {
                    name
                    isoCode
                    countryCode
                    latitude
                    longitude
                    suppliersCount
                }
                state {
                    name
                    isoCode
                    countryCode
                    latitude
                    longitude
                    suppliersCount
                }
            }
            billingAddress {
                id
                address
                additionalAddress
                zipcode
                country {
                    name
                    isoCode
                }
                state {
                    name
                    isoCode
                }
                city {
                    name
                    isoCode
                }
            }
            logo {
                original
                thumbnail
            }
            supplierProfile {
                id
                dispatchAddress {
                    id
                    address
                    additionalAddress
                    zipcode
                    country {
                        name
                        isoCode
                    }
                    state {
                        name
                        isoCode
                    }
                    city {
                        name
                        isoCode
                    }
                }
                process {
                    label
                    id
                }
                standardLeadTime
                language {
                    label
                    id
                }
                deliveryTerms {
                    id
                    label
                }
                paymentTerms {
                    id
                    label
                }
                isActive
            }
            isSupplier
            images {
                id
                original
                thumbnail
                nameWithExtension
            }
        }
    }
  `
}

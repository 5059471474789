import { hasPermission } from '@cranium/access'
import { useQuery } from '@cranium/resource'
import PropTypes from 'prop-types'
import { useContext, createContext, useEffect } from 'react'

import { access } from 'common/session'

import Categories from './categories.gql'
import { sortCategories } from './utils/sortCategories'


const CategoriesContext = createContext()

CategoriesProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export function CategoriesProvider({ children }) {
  const isLoggedIn = hasPermission(access.F_PROTECTED)
  const { data, request, isLoading } = useQuery(Categories, { namespace: 'categories', parseValue: 'data' })

  useEffect(() => {
    if(!data && !isLoading && isLoggedIn) {
      request()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn])


  return (
    <CategoriesContext.Provider
      value={{
        nace: sortCategories(data?.naceCategories),
        process: sortCategories(data?.processCategories),
        language: sortCategories(data?.languageCategories),
        payment: sortCategories(data?.paymentCategories),
        delivery: data?.deliveryCategories,
        gloss: data?.glossCategories,
        surface: data?.surfaceCategories,
        material: data?.materialCategories,
        tolerance: data?.toleranceCategories,
      }}
    >
      {children}
    </CategoriesContext.Provider>
  )
}

export function useCategories() {
  return useContext(CategoriesContext)
}

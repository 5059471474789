import { gettext } from '@cranium/i18n'
import { CheckCircle, Info, Warning, Error } from '@mui/icons-material'
import MuiAlert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import PropTypes from 'prop-types'
import { useContext, createContext, useState, useCallback, forwardRef } from 'react'

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={1} ref={ref} {...props} />
})

const defaultState = {
  autoHideDuration: 4000,
  message: gettext('Success'),
  type: 'success',
  anchorOrigin: { vertical: 'top', horizontal: 'right' },
}


const SnackbarContext = createContext()

SnackbarProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export function SnackbarProvider({ children }) {
  const [open, setOpen] = useState(false)
  const [snackbarProps, setSnackbarProps] = useState(defaultState)

  const showSnackbar = useCallback(({ type = 'success', ...props }) => {
    setSnackbarProps({ ...snackbarProps, type, ...props })
    setOpen(true)
  }, [snackbarProps])

  const handleClose = useCallback((event, reason) => {
    if(reason === 'clickaway') {
      return
    }
    setOpen(false)
  }, [])

  return (
    <>
      <SnackbarContext.Provider
        value={{ showSnackbar, handleClose }}
      >
        {children}
      </SnackbarContext.Provider>
      <Snackbar
        autoHideDuration={snackbarProps.autoHideDuration}
        anchorOrigin={snackbarProps.anchorOrigin}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={snackbarProps.type}
          sx={{ width: { xs: 320, sm: 384 } }}
          iconMapping={{
            success: <CheckCircle />,
            info: <Info />,
            warning: <Warning />,
            error: <Error />,
          }}
        >
          {snackbarProps.message}
        </Alert>
      </Snackbar>
    </>
  )
}

export function useSnackbar() {
  return useContext(SnackbarContext)
}

import sortBy from 'lodash/sortBy'

function sortCategories(list, iteratees = 'label') {
  if(!list?.length) {
    return []
  }
  return sortBy(list.map(item => {
    if(item.children && item.children.length) {
      return { ...item, children: sortCategories(item.children) }
    }
    return item
  }), iteratees)
}

export {
  sortCategories,
}

import { hasPermission } from '@cranium/access'
import Box from '@mui/system/Box'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'

import useMedia from 'common/hooks/useMedia'
import { access } from 'common/session'

import Header from './Header'
import Nav from './Nav'

AppLayout.propTypes = {
  children: PropTypes.node,
}

AppLayout.defaultProps = {
  children: null,
}

function AppLayout({ children }) {
  const { isDesktop } = useMedia()
  const isLoggedIn = hasPermission(access.F_PROTECTED)
  const [isNavOpen, setNavOpen] = useState(isDesktop)
  const handleNavigationToggle = useCallback((value) => setNavOpen(value), [])
  return (
    <>
      <Header handleNavigationToggle={handleNavigationToggle} isNavOpen={isNavOpen} isLoggedIn={isLoggedIn} />
      <Box
        sx={{
          display: 'flex',
          height: isLoggedIn ? { xs: 'calc(100% - 50px)', md: 'calc(100% - 72px)' } : '100%',
          pl: { xs: 0, sm: '56px', lg: 0 },
        }}
      >
        {isLoggedIn && <Nav isNavOpen={isNavOpen} />}
        <Box as="main" sx={{ flex: 1, overflow: 'auto' }}>
          {children}
        </Box>
      </Box>
    </>
  )
}

export default AppLayout

import { createContext, useContext, useEffect } from 'react'
import Loader from '../../widgets/Loader'
import { useQuery } from '@cranium/resource'
import PropTypes from 'prop-types'
import { hasPermission } from '@cranium/access'
import { access } from 'common/session'
import companyQueryParams from './utils/companyQueryParams'

const CompanyContext = createContext()
CompanyProvider.propTypes = {
  children: PropTypes.node.isRequired,
  companyId: PropTypes.string.isRequired,
}

export function CompanyProvider({ children, companyId }) {
  const isLoggedIn = hasPermission(access.F_PROTECTED)
  const { data, request, isLoading } = useQuery(companyQueryParams(companyId), { namespace: 'company', parseValue: 'data.Company' })

  useEffect(() => {
    if(isLoggedIn){
      request();
    }
  }, [isLoggedIn, request])

  return(
    <CompanyContext.Provider value={{ company: data, getCompany: request }}>
      <Loader isLoading={Boolean(!data && isLoading)}>
        {children}
      </Loader>
    </CompanyContext.Provider>
  )
}

export function useCompany() {
  return useContext(CompanyContext);
}

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import PropTypes from 'prop-types'

Loader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.any,
}

Loader.defaultProps = {
  children: null,
}

export default function Loader({ isLoading, children }) {
  return isLoading
    ? <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress />
    </Box>
    : children
}

import { CheckCache } from '@cranium/cache'
import { TranslateProvider } from '@cranium/i18n'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'


import { CategoriesProvider } from 'common/providers/categories'
import { SnackbarProvider } from 'common/providers/snackbar'
import { UserProvider } from 'common/providers/user'
import Router from 'common/router'
import AppAccess from 'common/session'
import { theme as themeObj } from 'common/utils/theme'

import API from './api'

App.propTypes = {
  store: PropTypes.object.isRequired,
}

const theme = createTheme(themeObj)


export default function App({ store }) {
  return (
    <TranslateProvider
      defaultLanguage="en"
      storage={localStorage}
      url="/jsi18n"
      api={API}
    >
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <CssBaseline />
          <Provider store={store}>
            <CheckCache>
              <AppAccess>
                <UserProvider>
                  <CategoriesProvider>
                    <Router />
                  </CategoriesProvider>
                </UserProvider>
              </AppAccess>
            </CheckCache>
          </Provider>
        </SnackbarProvider>
      </ThemeProvider>
    </TranslateProvider>
  )
}

import { Suspense, lazy } from 'react'

import Loader from 'common/widgets/Loader'

import ProfileLayout from './ProfileLayout'
const UserForm = lazy(() => import('./user'))
const CompanyForm = lazy(() => import('./company'))
const Members = lazy(() => import('./members'))

const routes = [
  {
    path: '/',
    layout: ProfileLayout,
    routes: [
      {
        path: '/',
        exact: true,
        redirectTo: 'user-info',
      },
      {
        path: '/user',
        component: () => <Suspense fallback={<Loader isLoading />}><UserForm/></Suspense>,
        name: 'user-info',
      },
      {
        path: '/company',
        component: () => <Suspense fallback={<Loader isLoading />}><CompanyForm/></Suspense>,
        name: 'company-profile',
      },
      {
        path: '/members',
        component: () => <Suspense fallback={<Loader isLoading />}><Members/></Suspense>,
        name: 'company-members',
      },
    ],
  },
]

export default routes

import { AccessProvider } from '@cranium/access'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import acessLevels from './access'


AppAccess.propTypes = {
  children: PropTypes.node.isRequired,
}

export default function AppAccess({ children }) {
  const session = useSelector(({ session }) => session)
  return (
    <AccessProvider acessLevels={acessLevels} session={session}>
      {children}
    </AccessProvider>
  )
}

import { reset } from '@cranium/cache'
import api from 'api'
import get from 'lodash/get'

export default function authMiddleware(store) {
  api.interceptors.response.use({
    onError: function({ data, response }) {
      return { data, response }
    },
    onSuccess: (consfigs) => {
      if(get(consfigs, 'data.errors[0].extensions.code') === 'NO_TOKEN') {
        store.dispatch(reset())
      }
      if(get(consfigs, 'data.errors[0].extensions.code') === 'ACCESS_DENIED') {
        window.location = '/'
      }
      return {
        ...consfigs,
      }
    },
  })
  api.interceptors.request.use({
    onSuccess: (consfigs) => {
      const headers = new Headers(consfigs.headers)
      headers.set('Authorization', `JWT ${get(store.getState(), 'session.data.token', '')}`)
      return {
        ...consfigs,
        headers,
      }
    },
  })
  return (next) => action => {
    return next(action)
  }
}

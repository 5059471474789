import { gettext } from '@cranium/i18n'
import { NavLink, Link } from '@cranium/router'
import FactoryIcon from '@mui/icons-material/Factory'
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox'
import OutboxOutlinedIcon from '@mui/icons-material/OutboxOutlined'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import TelegramIcon from '@mui/icons-material/Telegram'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import Box from '@mui/system/Box'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

import useMedia from 'common/hooks/useMedia'
import { useUser } from 'common/providers/user'

Nav.propTypes = {
  isNavOpen: PropTypes.bool.isRequired,
}

const navItemStyles = {
  display: 'flex',
  alignItems: 'center',
  padding: '12px',
  textDecoration: 'none',
  fontWeight: 700,
  color: 'grey.700',
  borderLeft: '2px solid transparent',
  transition: '0.2s background ease-in',
  '&:hover, &.active': {
    bgcolor: 'grey.100',
    borderLeftColor: 'primary.main',
  },
  '.MuiSvgIcon-root': {
    marginRight: 1,
  },
}

export default function Nav({ isNavOpen }) {
  const { user } = useUser()
  const location = useLocation()
  const { isMobile } = useMedia()
  const isSupplier = user?.company?.isSupplier

  if(isMobile && !isNavOpen) {
    return null
  }

  return (
    <Box
      as="nav"
      sx={{
        width: { xs: '100%', sm: isNavOpen ? 215 : 56 },
        position: { xs: 'fixed', lg: 'relative' },
        left: 0,
        height: { xs: 'calc(100% - 50px)', md: 'auto' },
        bottom: 0,
        top: { xs: 50, md: 72, lg: 0 },
        py: 2,
        borderRight: { xs: 0, sm: 1 },
        borderRightColor: 'grey.300',
        bgcolor: 'white',
        color: 'grey.300',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 11,
        transition: '0.2s all ease-in-out',
        fontSize: isNavOpen ? undefined : 0,
        overflow: 'hidden',
      }}
    >
      <Box
        as={Link}
        className={location.pathname.includes('price-requests') && !location.search.includes('isFromPurchase') && 'active'}
        sx={navItemStyles}
        to="price-requests"
      >
        <TelegramIcon/>
        {gettext('RFQ')}
      </Box>
      <Box
        as={Link}
        className={(location.pathname.includes('purchase-parts') || location.search.includes('isFromPurchase')) && 'active'}
        sx={navItemStyles}
        to="purchase-parts"
      >
        <MoveToInboxIcon/>
        {gettext('Purchase parts')}
      </Box>
      <Box
        as={NavLink}
        sx={navItemStyles}
        to="proposals"
      >
        <OutboxOutlinedIcon />
        {gettext('Sales parts')}
      </Box>
      <Box
        as={NavLink}
        sx={navItemStyles}
        to="orders"
      >
        <ShoppingCartIcon />
        {gettext('Purchases')}
      </Box>
      {isSupplier && (
      <Box
        as={NavLink}
        sx={navItemStyles}
        to="incoming-orders"
      >
        <TrendingUpIcon />
        {gettext('Sales')}
        </Box>
      )}
      <Box
        as={NavLink}
        sx={navItemStyles}
        to="suppliers"
      >
        <FactoryIcon />
        {gettext('Suppliers')}
      </Box>
    </Box>
  )
}

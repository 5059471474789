import { gettext } from '@cranium/i18n'
import { withRouter } from '@cranium/router'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Box from '@mui/system/Box'
import PropTypes from 'prop-types'

import NotFound from '@img/404.png'

EmptyPlaceholder.propTypes = {
  title: PropTypes.node,
  description: PropTypes.node,
  image: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.node,
  history: PropTypes.object.isRequired,
}

EmptyPlaceholder.defaultProps = {
  title: gettext('Oh...'),
  description: gettext('You have got nothing.'),
  image: NotFound,
  link: 'root',
  linkText: gettext('Go to homepage'),
}

function EmptyPlaceholder({ title, description, image, link, linkText, history }) {
  return (
    <Container
      sx={{
        borderRadius: 1,
        borderWidth: { xs: 0, sm: 1 },
        borderStyle: 'solid',
        borderColor: 'grey.300',
        minHeight: 580,
        p: { xs: 5, sm: 13 },
        display: 'flex',
        alignItems: 'center',
        flexDirection: { xs: 'column', sm: 'row' },
      }}
      maxWidth="container"
    >
      {image && <Box as="img" sx={{ objectFit: 'contain' }} src={image} width={280} height={280} mr={{ xs: 0, sm: 13 }}/>}
      <Box textAlign={{ xs: 'center', sm: 'left' }} flex={1}>
        <Typography mb={3} variant="h4" color="primary.main">{title}</Typography>
        <Box as="p" m={0} fontSize={20} fontWeight={700}>{description}</Box>
        {link && <Button sx={{ mt: 6 }} onClick={() => history.push(link)}>{linkText}</Button>}
      </Box>
    </Container>
  )
}

export default withRouter(EmptyPlaceholder)

export const theme = {
  typography: {
    button: {
      fontWeight: 700,
      lineHeight: 1.6,
      textTransform: 'none',
    },
    color: '#39414D',
    fontFamily: [
      "'Manrope'",
      'sans-serif',
    ].join(','),
    h1: {
      fontWeight: '700',
    },
    h2: {
      fontWeight: '700',
    },
    h3: {
      fontWeight: '700',
    },
    h4: {
      fontWeight: '700',
    },
    h5: {
      fontWeight: '700',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1148,
      xl: 1400,
    },
  },
  palette: {
    primary: {
      light: '#29C6CD',
      main: '#24ACB3',
      dark: '#1E8F94',
      contrastText: '#fff',
    },
    secondary: {
      light: '#F0A8A8',
      main: '#F67575',
      dark: '#BD0F0F',
      contrastText: '#fff',
    },
    info: {
      main: '#0C6FF2',
    },
    warning: {
      main: '#EFCB0E',
    },
    error: {
      main: '#F04242',
    },
    success: {
      main: '#36B160',
    },
    grey: {
      100: '#F7F9FC',
      200: '#E8ECF2',
      300: '#C9CFD6',
      400: '#A0A8B2',
      500: '#596980',
      600: '#475466',
      700: '#39414D',
      800: '#292D33',
      900: '#0F1113',
    },
  },
  shadows: [
    '0px 1px 4px rgba(11, 62, 65, 0.25)',
    '0px 4px 8px rgba(11, 62, 65, 0.15)',
    '0px 5px 20px rgba(11, 62, 65, 0.15)',
    '0px 10px 30px rgba(11, 62, 65, 0.15)',
    '0px 10px 40px rgba(11, 62, 65, 0.1)',
  ],
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '#root': {
          height: '100%',
        },
        html: {
          height: '100%',
        },
        body: {
          height: '100%',
          color: '#39414D',
        },
        a: {
          color: '#39414D',
        },
        '.input-wrap .react-tel-input': {
          '.form-control': {
            width: '100%',
            padding: '11px 14px 10px 60px',
          },
          '.MuiOutlinedInput-notchedOutline': {
            border: 0,
          },
          '.MuiOutlinedInput-root': {
            position: 'static',
          },
          '.MuiFormHelperText-root.Mui-error': {
            position: 'absolute',
            top: 56,
            width: '100%',
            left: 0,
          },
          '.selected-flag': {
            width: '60px',
            borderRight: '1px solid #C9CFD6',
            padding: 16,
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          color: '#39414D',
          borderBottom: '3px solid #36B160',
          borderRadius: 2,
          padding: '8px 24px',
          fontWeight: 700,
        },
        standardError: {
          borderColor: '#F04242',
        },
        standardWarning: {
          borderColor: '#EFCB0E',
        },
        standardInfo: {
          borderColor: '#0C6FF2',
        },
        icon: {
          opacity: 1,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '0.96rem',
          '&.Mui-focused': {
            color: '#0C6FF2',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: 1,
            borderColor: '#0C6FF2',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: {},
        outlinedPrimary: {
          borderColor: '#C9CFD6',
        },
        sizeSmall: {
          fontSize: 14,
          padding: '2px 20px',
        },
        sizeMedium: {
          fontSize: 18,
          padding: '6px 20px',
        },
        sizeLarge: {
          fontSize: 20,
          padding: '8px 20px',
        },
        text: {
          color: '#0C6FF2',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 40,
        },
        indicator: {
          height: 1,
          zIndex: 1,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: 16,
          padding: '8px 16px',
          minHeight: 40,
          '&.Mui-selected': {
            color: '#39414D',
            backgroundColor: '#F7F9FC',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: 14,
          padding: '8px 16px',
          minHeight: 40,
          '&:hover, &.Mui-selected': {
            backgroundColor: '#F7F9FC',
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          border: '1px solid #E8ECF2',
          borderRadius: 4,
          minWidth: 236,
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        enterTouchDelay: 10,
      },
      styleOverrides: {
        popper: {
          opacity: 1,
        },
        tooltip: {
          padding: 16,
          fontSize: 14,
          borderRadius: 8,
          whiteSpace: 'pre-line',
          color: '#39414D',
          boxShadow: '0px 4px 8px rgba(11, 62, 65, 0.15)',
          backgroundColor: '#fff',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          fontSize: 14,
          border: '1px solid #A0A8B2',
          backgroundColor: '#E8ECF2',
          '.MuiButtonBase-root': {
            display: 'none',
          },
        },
        deleteIcon: {
          color: '#39414D',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          '&.dashboard': {
            borderSpacing: 0,
            borderCollapse: 'separate',
            borderRadius: 4,
            tableLayout: 'fixed',
            border: '1px solid #C9CFD6',
            'td, th': {
              height: 50,
              border: 0,
              color: '#39414D',
            },
            'tbody > tr:nth-of-type(even)': {
              backgroundColor: '#F7F9FC',
              '&:hover': {
                backgroundColor: '#EDF8F8',
              },
            },
            'th:not(:last-child), td:not(:last-child)': {
              borderRight: '1px solid #E8ECF2',
            },
            'tbody>tr:first-of-type>td': {
              borderTop: '1px solid #C9CFD6',
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 14,
          color: '#596980',
        },
        head: {
          fontWeight: 600,
          fontSize: 12,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.MuiTableRow-hover:hover': {
            backgroundColor: '#EDF8F8',
            '.MuiTableCell-body': {
              color: '#39414D',
            },
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        img: {
          objectFit: 'contain',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: '100%',
          margin: '16px auto',
          padding: '0 16px',
          '@media (min-width: 600px)': {
            maxWidth: 'calc(100% - 48px)',
            margin: '50px auto',
            padding: 0,
          },
          '@media (min-width: 1100px)': {
            maxWidth: 880,
          },
        },
        maxWidthContainer: {
          '@media (min-width: 1400px)': {
            maxWidth: 1148,
          },
        },
      },
    },
  },
}

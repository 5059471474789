import { gettext } from '@cranium/i18n'
import { withRouter } from '@cranium/router'
import Container from '@mui/material/Container'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import { useCallback } from 'react'


ProfileLayout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}


function ProfileLayout({ children, location, history }) {
  const navigate = useCallback((name) => {
    return () => history.push(name)
  }, [history])
  return (
    <Container sx={{ pb: 2 }} maxWidth="container">
      <Typography variant="h5" sx={{ mb: 2, fontSize: { xs: 14, sm: 20 } }}>{gettext('Account settings')}</Typography>
      <Tabs value={location.state.name} sx={{ mb: 3, position: 'relative', '&:after': { content: "''", height: '1px', width: '100%', backgroundColor: 'grey.300', position: 'absolute', bottom: 0, left: 0 } }}>
        <Tab sx={{ maxWidth: { xs: 120, sm: 'inherit' } }} label={gettext('User profile')} onClick={navigate('user-info')} value="user-info" />
        <Tab sx={{ maxWidth: { xs: 120, sm: 'inherit' } }} label={gettext('Company information')} onClick={navigate('company-profile')} value="company-profile" />
        <Tab sx={{ maxWidth: { xs: 120, sm: 'inherit' } }} label={gettext('Company members')} onClick={navigate('company-members')} value="company-members" />
      </Tabs>
      {children}
    </Container>
  )
}

export default withRouter(ProfileLayout)
